export const ProfileList = [
  {
    name: 'ADMIN'
  },
  {
    name: 'FM'
  },
  {
    name: 'STORE_INCHARGE'
  },
  {
    name: 'ACCOUNTS_MANAGER'
  },
  {
    name: 'ACCOUNTS_ASSISTANT'
  },
  {
    name: 'HR'
  },
  {
    name: 'VIPRAS_MART'
  },
  {
    name: 'BDM'
  },
  {
    name: 'CRM'
  },
  {
    name: 'CRM_ASSISTANT'
  },
  {
    name: 'GM'
  },
  {
    name: 'OPS'
  },
  {
    name: 'SUPER_ADMIN'
  }
];
