import axios from 'axios';
import { settings } from 'config';

export const getCompareAgreedActual = (token, month, year) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/site/AllSiteComparison/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
