import axios from 'axios';
import { settings } from 'config';

// const getSalaryBySitecode = (siteId, month, year, token) => {
//   return axios.get(
//     `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`,
//     {
//       headers: {
//         'erp-token': token
//       }
//     }
//   );
// };

const getSalaryBySitecode = async (
  siteId,
  month,
  year,
  token,
  status = '',
  searchType = '',
  searchKeyword = ''
) => {
  // Base URL construction
  let url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`;

  // Initialize the filter object
  const filter = {};

  // Add status filter if available
  if (status) {
    filter.status = status;
  }

  // Add name and nameBydata filters if available
  if (searchType && searchKeyword) {
    filter[searchType] = searchKeyword;
  }

  // Append filter to the URL if any filter is applied
  if (Object.keys(filter).length > 0) {
    const serializedFilter = JSON.stringify(filter);
    url += `?filter=${serializedFilter}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });

    return response;
  } catch (error) {
    throw new Error(
      `API error: ${
        error?.response?.data?.message || error?.message || 'Unknown error'
      }`
    );
  }
};

const getClubedSalaryBySitecode = async (month, year, token) => {
  const filter = { isMultiSite: true };
  const serializedData = JSON.stringify(filter);
  let url = `${settings.apiUrl}/api/v1/salary/ALL/${month}/${year}?filter=${serializedData}`;
  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getInactiveSalaryBySitecode = async (
  siteId,
  month,
  year,
  token,
  salaryPaid,
  salaryNotPaid,
  searchType,
  searchKeyword
) => {
  let url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}`;

  const filter = {};

  if (salaryPaid) {
    filter.isSalaryPaid = salaryPaid;
  } else if (salaryNotPaid === 'false') {
    filter.isSalaryPaid = 'false';
  }

  if (searchType && searchKeyword) {
    filter[searchType] = searchKeyword;
  }

  if (Object.keys(filter).length > 0) {
    const serializedFilter = JSON.stringify(filter);
    url += `?filter=${serializedFilter}`;
  }

  // if (salaryPaid) {
  //   console.log('salaryPaid ');
  //   const filter = { isSalaryPaid: salaryPaid };
  //   const serializedData = JSON.stringify(filter);
  //   url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}?filter=${serializedData}`;
  // } else if (salaryNotPaid === 'false') {
  //   const filter = { isSalaryPaid: 'false' };
  //   const serializedData = JSON.stringify(filter);
  //   url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}?filter=${serializedData}`;
  // } else {
  //   url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}`;
  // }

  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getSalaryByEmployee = (employeeId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/byEmployee/${employeeId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateSalaryReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/generateSalaryReport/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateInvoiceReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice/create/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateSitePLReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose/generateProfitLoseReport/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateSalaryReport = (data, status, month, token) =>
  axios.post(
    `${settings.apiUrl}/api/v1/salary/update/byemployee/${month}/${status}`,
    data,
    {
      headers: {
        'erp-token': token
      }
    }
  );

export default {
  getSalaryBySitecode,
  getSalaryByEmployee,
  generateSalaryReportBySiteId,
  generateInvoiceReportBySiteId,
  getInactiveSalaryBySitecode,
  updateSalaryReport,
  getClubedSalaryBySitecode,
  generateSitePLReportBySiteId
};
