import axios from 'axios';
import { settings } from 'config';

export const getAcessMenu = (token, role) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/access?filter={ "role": "${role}"}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export const getAcessMenuByRole = (token, role) => {
  return axios.get(`${settings.apiUrl}/api/v1/access/verified/${role}`, {
    headers: {
      'erp-token': token
    }
  });
};

export const updateAccessMenu = (data, token) => {
  const idToUpdate = data?.id;
  const url = `${settings.apiUrl}/api/v1/access/${idToUpdate}`;
  return axios.put(url, data, { headers: { 'erp-token': token } });
};
