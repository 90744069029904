import axios from 'axios';
import { settings } from 'config';
const postExpenses = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/expenses`, data, {
    headers: {
      'erp-token': token
    }
  });

const getAllExpenses = token => {
  return axios.get(`${settings.apiUrl}/api/v1/expenses/allexpenses`, {
    headers: {
      'erp-token': token
    }
  });
};

const getByImpressHolderDropDown = token => {
  return axios.get(
    `${settings.apiUrl}/api/v1/users/list/allUserDropdown/impressHolder`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  postExpenses,
  getAllExpenses,
  getByImpressHolderDropDown
};
